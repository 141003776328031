import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import upperFirst from 'lodash/upperFirst'
import {
  HStack,
  InputGroup,
  Status,
  Text,
  Token,
  VStack,
  SelectOptionItemType,
  ActionButton,
  Details,
  Widget,
} from '@revolut/ui-kit'
import { Lightning } from '@revolut/icons'
import { getTestConnection, postTestConnection } from '@src/api/dataAnalytics'
import { ROUTES } from '@src/constants/routes'
import { selectorKeys } from '@src/constants/api'
import { Statuses } from '@src/interfaces'
import { ConnectionInterface } from '@src/interfaces/dataAnalytics'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ArchiveButton } from '@src/features/SettingsButtons'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import { pushError } from '@src/store/notifications/actions'
import { checkIsDirty, getStatusIcon, getStatusText, safeJsonParse } from './utils'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'

const backUrl = ROUTES.SETTINGS.ANALYTICS
const randomPassword = Math.random().toString(36).slice(2)
const requiredFields = ['type', 'host', 'port', 'username', 'password']

export interface OptionInterface {
  id: string
  name: string
  fields: string[]
}

export const ConnectionForm = ({
  readOnly,
  filterOptions,
}: {
  readOnly: boolean
  filterOptions?: (
    options: SelectOptionItemType<OptionInterface>[],
  ) => SelectOptionItemType<OptionInterface>[]
}) => {
  const { initialValues, values } = useLapeContext<ConnectionInterface>()
  const { query } = useQuery<{ title?: string }>()
  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector(selectUser)
  const [isTested, setIsTested] = useState(false)
  const [optionalFields, setOptionalFields] = useState<string[]>([])

  const [error, setError] = useState('')

  const { options: connectionTypes } = useFetchOptions<OptionInterface>(
    selectorKeys.connection_types,
  )

  useEffect(() => {
    if (values.type) {
      const connectionType = connectionTypes.find(item => item.value.id === values.type)
      const fields =
        connectionType?.value.fields.filter(item => !requiredFields.includes(item)) || []
      setOptionalFields(fields)
    }
  }, [connectionTypes, values.type])

  useEffect(() => {
    if (initialValues && !initialValues.owner?.id) {
      values.owner = { id: user.id, name: user.full_name }
    }
    if (initialValues && query.title && !initialValues.name) {
      values.name = query.title
    }
  }, [initialValues])

  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    if (values.id) {
      values.password = randomPassword
    }

    if (values.extra) {
      values.extra = JSON.stringify(values.extra)
    }
  }, [])

  useEffect(() => {
    if (values.id) {
      setIsDirty(checkIsDirty(initialValues, values, randomPassword))
    }
  }, [JSON.stringify(values)])

  useEffect(() => {
    if (isDirty && values.password === randomPassword) {
      values.password = undefined
    }
  }, [isDirty])

  const getTestValues = () => {
    if (values.extra) {
      const [err, result] = safeJsonParse(values.extra)

      if (err) {
        setError(`Failed to parse extra field: ${err.message}`)
        setIsTested(true)
        return null
      }
      return { ...values, extra: result }
    }
    return values
  }
  const handlePostTestConnection = async () => {
    setIsLoading(true)
    setError('')

    try {
      const testValues = getTestValues()

      if (!testValues) {
        return
      }

      const result = await postTestConnection(testValues)
      if (!result.data.success) {
        setError(result.data.detail)
      }
    } catch (e) {
      setError(e.message)
      pushError({ error: e })
    } finally {
      setIsLoading(false)
      setIsTested(true)
    }
  }

  const handleGetTestConnection = async () => {
    setIsLoading(true)
    setError('')

    try {
      const result = await getTestConnection(values.id)
      if (!result.data.success) {
        setError(result.data.detail)
      }
    } catch (e) {
      setError(e.message)
      pushError({ error: e })
    } finally {
      setIsLoading(false)
      setIsTested(true)
    }
  }
  return (
    <VStack gap="s-16">
      <InputGroup>
        <LapeNewInput label="Name" name="name" required />
        <LapeNewInput label="Description" name="description" />
        <LapeRadioSelectInput
          label="Owner"
          name="owner"
          selector={selectorKeys.employee}
        />
        <LapeRadioSelectInput
          label="Type"
          name="type"
          onChange={val => {
            if (val?.id) {
              values.type = val.id
            }
          }}
          options={filterOptions ? filterOptions(connectionTypes) : connectionTypes}
          value={connectionTypes.find(item => item.value.id === values.type)?.value}
        />
      </InputGroup>
      {values.type && (
        <Widget p="s-16">
          <Text
            color={Token.color.greyTone50}
            mb="s-6"
            variant="caption"
            use="p"
          >{`${upperFirst(values.type)} settings`}</Text>
          <InputGroup>
            <InputGroup variant="horizontal">
              <LapeNewInput
                label="Server"
                name="host"
                readOnly={readOnly}
                required
                width="70%"
              />
              <LapeNewInput
                label="Port"
                name="port"
                readOnly={readOnly}
                required
                type="number"
              />
            </InputGroup>
            <InputGroup variant="horizontal">
              <LapeNewInput
                label="Username"
                name="username"
                readOnly={readOnly}
                required
              />
              <LapeNewInput
                label="Password"
                name="password"
                readOnly={readOnly}
                required
                type="password"
              />
            </InputGroup>
            <InputGroup>
              {optionalFields.map(optionalField => {
                return (
                  <LapeNewInput
                    key={optionalField}
                    label={upperFirst(optionalField)}
                    name={optionalField}
                    readOnly={readOnly}
                  />
                )
              })}
            </InputGroup>
          </InputGroup>
          <HStack gap="s-8" mt="s-16" align={error ? 'normal' : 'center'}>
            <ActionButton
              disabled={isLoading}
              onClick={
                values.id && !isDirty ? handleGetTestConnection : handlePostTestConnection
              }
              pending={isLoading}
              useIcon={Lightning}
            >
              Test Connection
            </ActionButton>
            {!isLoading && (
              <VStack width="100%" align="end">
                <Status
                  color={isTested ? Token.color.foreground : Token.color.greyTone50}
                  iconColor={error ? 'red' : 'green'}
                  useIcon={getStatusIcon(isTested, error)}
                  variant="caption"
                >
                  {getStatusText(isTested, error, values.id)}
                  {error && (
                    <>
                      <br />
                      <Text variant="caption">{error}</Text>
                    </>
                  )}
                </Status>
              </VStack>
            )}
          </HStack>
        </Widget>
      )}
    </VStack>
  )
}

export const GeneralConnectionForm = connect(() => {
  const { id } = useParams<{ id: string }>()
  const { values, submit } = useLapeContext<ConnectionInterface>()

  const permissions = values.field_options?.permissions || []
  const canChangeConnection = values?.id
    ? permissions?.includes?.(PermissionTypes.ChangeConnection)
    : true

  return (
    <>
      <PageBody>
        <Details>
          <Details.Title>
            <VStack gap="s-8">
              <Text variant="h4" color={Token.color.foreground}>
                Connection Details
              </Text>
              <Text variant="caption">Please enter the connection details below</Text>
            </VStack>
          </Details.Title>
          <Details.Content>
            <ArchiveButton
              entityPermissions={EntityPermissions.Change}
              isVisible={!!values?.id}
              onBeforeArchive={() => {
                if (values.password === randomPassword) {
                  delete values.password
                }
              }}
              showDialog
              unArchiveStatus={Statuses.active}
            />
          </Details.Content>
          <Details.Note mt="s-8">
            <ConnectionForm readOnly={!canChangeConnection} />
          </Details.Note>
        </Details>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          afterSubmitUrl={backUrl}
          disabled={!canChangeConnection}
          hideWhenNoChanges={false}
          onClick={() => {
            if (values.password === randomPassword) {
              delete values.password
            }

            if (values.extra) {
              values.extra = JSON.parse(values.extra.replace(/'/g, '"'))
            }

            return submit()
          }}
          successText={
            id ? 'Connection successfully updated.' : 'Connection successfully created.'
          }
          useValidator
        />
      </PageActions>
    </>
  )
})
