import React, { useState } from 'react'
import {
  Avatar,
  Widget,
  Flex,
  Icon,
  Placeholder,
  Text,
  Token,
  TextButton,
  HStack,
} from '@revolut/ui-kit'
import { UpdateTypes } from '@src/interfaces/kpis'

import { updateTypeOptionMap } from './updateTypeOptionMap'
import { FormattedMessage } from 'react-intl'
import Form from '@src/features/Form/Form'
import InputsSkeleton from '@src/components/Skeletons/InputsSkeleton'
import { integrationApiFormMap, serverNameValidators } from './constants'
import { lookerIntegrationPreferences } from '@src/api/integrations'

import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'

import { FeatureFlags } from '@src/store/auth/types'

import { ROUTES } from '@src/constants/routes'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { ConnectionType } from '@src/pages/Hub/Integrations/useMakeConnection'
import { IntegrationEnablePopup } from '@src/pages/Hub/Integrations/components/IntegrationEnablePopup'
import { pathToUrl } from '@src/utils/router'

interface Props {
  type: UpdateTypes
}

const connectionUpdateTypeToConnectionTypeMap: Record<
  UpdateTypes,
  ConnectionType | undefined
> = {
  [UpdateTypes.looker]: 'looker',
  [UpdateTypes.roadmap]: 'jira',
  [UpdateTypes.tableau]: 'tableau',
  [UpdateTypes.clickup]: 'clickup',
  [UpdateTypes.manual]: undefined,
  [UpdateTypes.sql]: undefined,
}

export const NonIntegratedType = ({ type }: Props) => {
  const { name, placeholderDescription, icon, image, iconBackground } =
    updateTypeOptionMap[type]
  const featureFlags = useSelector(selectFeatureFlags)
  const isJiraOauth = featureFlags?.includes(FeatureFlags.JiraOauthIntegration)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const openInNewTab = useOpenNewTab()

  const closePopup = () => {
    setIsPopupOpen(false)
  }

  const handleConnect = async () => {
    if (type === UpdateTypes.looker) {
      const resp = await lookerIntegrationPreferences.get({ id: '1' })
      if (resp.data?.method?.id === 'basic') {
        // for the existing tennants we need to support connecting to looker without oauth
        // where to manage connections in analytics tab
        openInNewTab(ROUTES.SETTINGS.ANALYTICS)
        return
      }
    }
    if (type === UpdateTypes.sql) {
      openInNewTab(
        pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.GENERAL, undefined, {
          title: 'SQL Database',
        }),
      )
      return
    }
    if (type === UpdateTypes.roadmap) {
      if (!isJiraOauth) {
        openInNewTab(ROUTES.FEATURES.INTEGRATION.JIRA)
        return
      }
    }
    setIsPopupOpen(true)
  }

  return (
    <>
      <Widget>
        <Placeholder>
          <Flex alignItems="center" gap="s-8" py="s-24">
            <Avatar
              useIcon="LogoRevolut"
              color={Token.color.white}
              bg={Token.color.blue}
              variant="square"
            />
            <Icon name="Link" />
            <Avatar image={image} useIcon={icon} bg={iconBackground} />
          </Flex>
          <Placeholder.Title>
            <FormattedMessage
              values={{ name }}
              defaultMessage="Connect {name} to get started"
              id="performance.goalForm.connection.placeholder.title"
            />
          </Placeholder.Title>
          <Placeholder.Description>{placeholderDescription}</Placeholder.Description>
          <Placeholder.Actions>
            <TextButton onClick={handleConnect}>
              <HStack align="center" space="s-8">
                <Icon name="Link" size="s-16" />
                <Text variant="emphasis2">
                  <FormattedMessage
                    values={{ name }}
                    defaultMessage="Connect {name}"
                    id="performance.goalForm.connection.placeholder.cta"
                  />
                </Text>
              </HStack>
            </TextButton>
          </Placeholder.Actions>
        </Placeholder>
      </Widget>
      <Form
        loadingState={<InputsSkeleton />}
        api={integrationApiFormMap[type]}
        validator={serverNameValidators[type]}
        forceParams={{ id: '1' }}
      >
        {connectionUpdateTypeToConnectionTypeMap[type] && (
          <IntegrationEnablePopup
            isOpen={isPopupOpen}
            onClose={closePopup}
            type={connectionUpdateTypeToConnectionTypeMap[type] as ConnectionType}
          />
        )}
      </Form>
    </>
  )
}
